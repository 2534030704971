import React from 'react';
import { Trans, useTranslation } from "react-i18next"

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

const JobPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="СOO (Chief Operating Officer)" />
      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>СOO (Chief Operating Officer)</h1>
          <Trans i18nKey="careers.chiefOperatingOfficer.text1">
            <p className={styles.text}>NutritionPro je jedním z nejrychleji rostoucích Foodtech startupů v ČR (500 %+ meziroční růst).</p>
            <p className={styles.text}>
            Přišli jsme na trh s námi vyvinutým algoritmem, který je na základě údajů o Tvém těle schopen vytvořit jídelníček přímo Tobě na míru. Tím to ale nekončí – jídlo pro Tebe uvaříme a dovezeme až ke dveřím!
            </p>
            <p className={styles.text}>
            Nedávno jsme obdrželi investici pro další expanze, a proto hledáme СOO (Chief Operating Officer).
            </p>
            <p className={styles.text}>
            Vyrábíme stovky tisíc jídel měsíčně a jsme největší společnost v České Republice, která poskytuje personalizované jídelníčky s kompletním servisem.
            </p>
            <p className={styles.text}>
            Odvádíme kvalitní a smysluplnou práci, která vede ke spokojenosti všech našich klientů. Jsme tým mladých lidí, který k sobě hledá nové kolegy s podobným „mindsetem“. Pokud to máš stejně, tak ti zaručíme tři věci: stabilitu, potenciál růstu a práci s unikátním produktem.
            </p>
            <p className={styles.text}>
            Jestli chceš být součástí nadšeného týmu a rozšířit supercrew úspěšného startupu,  máš tah na branku, nebojíš se realizovat vlastní nápady a nechceš si nechat ujít šanci a příležitost skočit do nejžhavějšího startupového odvětví, FoodTech, tak čti dál!
            </p>
          </Trans>

          <Trans i18nKey="careers.chiefOperatingOfficer.list1">
            <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
            <ul className={styles.textUl}>
              <li className={styles.newList}>
              Dohled nad každodenním fungováním společnosti a nad prací manažerů
              </li>
              <li className={styles.newList}>
              Návrh a implementace operačních strategií, plánů a postupů
              </li>
              <li className={styles.newList}>
              Vytváření postupů, které podporují firemní účely a vízi
              </li>
              <li className={styles.newList}>
              Podpora zaměstnanců, aby dosáhovali maximálních výkonů (KPI, OKR)
              </li>
              <li className={styles.newList}>
              Stanovení cílů pro výkonnost firmy a jejich vyhodnocování analýzou a interpretací údajů, resp. analýzy, reporty, hodnocení a návrhy řešení
              </li>
              <li className={styles.newList}>
              Účast na expanzivních aktivitách (investice, akvizice atd.)
              </li>
              <li className={styles.newList}>
              Správa vztahů s partnery/dodavateli
              </li>
            </ul>
          </Trans>

          <Trans i18nKey="careers.chiefOperatingOfficer.list2">
            <h3 className={styles.textSubHeader}>Jak bychom tě představovali? </h3>
            <ul className={styles.textUl}>
              <li className={styles.newList}>
              Jsi data-driven achiever a čísla jsou pro tebe hlavním měřítkem - dokážeš si profesionálně řídit podle metrik, analyzovat je a dle výsledků přizpůsobovat celkovou strategii.
              </li>
              <li className={styles.newList}>
              Umíš velmi dobře komunikovat a povahou jsi dotahovač věcí do konce.
              </li>
              <li className={styles.newList}>
              5+ let praxe na vedoucích pozicích
              </li>
              <li className={styles.newList}>Zkušenosti z pozice projektového koordinátora jsou výhodou
              </li>
            </ul>
          </Trans>

          <Trans i18nKey="careers.chiefOperatingOfficer.list3">
            <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
            <ul className={styles.textUl}>
              <li className={styles.newList}>Motivační finanční ohodnocení a měsíční bonus
              </li>
              <li className={styles.newList}>Nárok na zaměstnanecké akcie (ESOP)
              </li>
              <li className={styles.newList}>
              Plně hrazené vzdělávací kurzy a konzultace s předními odborníky v oboru
              </li>
              <li className={styles.newList}>
              Firemní obědy zdarma
              </li>
              <li className={styles.newList}>
              Multisport kartu plně hrazenou firmou
              </li>
              <li className={styles.newList}>
              Vodu, kávu, čaj, džusy, čerstvé ovoce na pracovišti dle libosti
              </li>
              <li className={styles.newList}>
              Sick-days
              </li>
            </ul>
          </Trans>

          <p className={styles.text}>
            {t("careers.text2")}
          </p>

          <h3 className={styles.textSubHeader}>
            {t("careers.textSubHeader2")}
          </h3>
          <h3 className={styles.textSubHeader}>
            {t("careers.textSubHeader3")}
          </h3>
          <a className={styles.jobsEmail} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            {t("careers.backButton")}
          </a>
        </div>
      </div>
    </>
  )
}

export default JobPage